
import { computed, reactive, ref } from 'vue'
import { User, createUser, DelFlag } from 'momai'
import { useRoute } from 'vue-router'
import { fromNow } from '@/utils/format'
import { api } from '@/api/useAxios'
import { Toast } from 'vant'
import { update as updateUser, detail as fetchUserInfo } from '@/api/user'
import { getDelFlag } from '@/utils/auth'

export default {
  setup: () => {
    const userDetail = ref<User>(createUser())
    const { userId } = useRoute().params
    const showChangeMagicBean = ref(false)
    const magicBeanNumberKeyboard = ref(false)
    const showAdminChooser = ref(false)
    const checkedRole = ref<DelFlag>()
    fetchUserInfo(userId).then(r => {
      userDetail.value = r
      checkedRole.value = userDetail.value.delFlag
    })
    const magicBeanForm = reactive({
      changetype: true,
      scolevalue: 0,
      userid: userId
    })
    const delflag = getDelFlag()
    return {
      delflag,
      fromNow,
      showAdminChooser,
      showChangeMagicBean,
      magicBeanNumberKeyboard,
      userDetail,
      magicBeanForm,
      checkedRole,
      userClasses: computed(() => userDetail.value.rsrv1?.split(',')),
      setMagicBeanNum: () => {
        api.post('/sysuser/scolechange', magicBeanForm).then(() => {
          if (magicBeanForm.changetype) {
            userDetail.value.score += Number(magicBeanForm.scolevalue)
          } else {
            userDetail.value.score -= magicBeanForm.scolevalue
          }
          magicBeanForm.changetype = true
          magicBeanForm.scolevalue = 0
          showChangeMagicBean.value = false
          Toast.success('积分变更成功！')
        })
      },
      setUserAdmin: () => {
        updateUser({
          id: Number(userId),
          delFlag: checkedRole.value
        }).then(() => {
          Toast.success('设置成功')
          showAdminChooser.value = false
        })
      }
    }
  }
}
